export * from "../getFiles/models"

export type FileMeta = {
    id: string
    mimeType: string
    fileSize: number
    displayName: string
    prefix: string
    userPrefix: string
    url: string
    description: string
    expiresAt: Date
    createdAt: Date
    updatedAt: Date
    commited: boolean
    kind: FileKind
    labels: Array<string>
}

export enum FileKind {
    Document,
    Image,
    Video,
    Thumbnail,
    Other,
    Directory,
    Audio,
    Archive,
}
