import { ajax, removeStoredLogin, setStoredLogin, StoredLogin } from "@tm/utils"
import { AppConfig } from "../data/loadConfiguration"
import { handleLoginSuccess } from "./handleLoginOutcome"
import { GetAuthTokenAnonymousRequest, GetAuthTokenResponse } from "@tm/models"

export async function handleAnonymousLogin(catalog: string, appConfig: AppConfig): Promise<boolean> {
    const { login: { authId, serviceUrl } } = appConfig

    if (authId) {

        const appAuthId = (typeof authId === "object" && authId.default) ? authId.default : authId as string

        const params = new URLSearchParams(window.location.search)

        const debugApiKey = params.get("debugApiKey") ?? undefined
        const debugLoggingFlags = Number(params.get("debugLoggingFlags")) || undefined

        const request: GetAuthTokenAnonymousRequest = {
            authId: appAuthId,
            languageId: parseInt(appConfig.locale),
            debugApiKey: debugApiKey,
            debugLoggingFlags: debugLoggingFlags,
            loginInterface: "frontend"
        }

        const tokenResponse = await getAuthTokenAnonymous(serviceUrl, request)

        if (tokenResponse.error || !tokenResponse.token) {
            removeStoredLogin()
            return false
        }

        const storedLogin: StoredLogin = {
            token: tokenResponse.token,
            schema: "Bearer",
            expireDate: new Date(Date.now() + 43199 * 1000),
            locale: appConfig.locale,
            username: ""
        }

        setStoredLogin(storedLogin)
        handleLoginSuccess(catalog)

        return true
    }

    return false
}

function getAuthTokenAnonymous(serviceUrl: string, request: GetAuthTokenAnonymousRequest): Promise<GetAuthTokenResponse> {
    const url = `${serviceUrl}/GetAuthTokenByAuthId`

    return new Promise<GetAuthTokenResponse>((resolve, reject) =>
        ajax({ url, method: "POST", body: request }).then(
            (data) => resolve(data),
            reject
        )
    )
}