import { History } from 'history';
import { BaseConfig } from './loadBaseConfig';
import { hasValidStoredLogin } from '@tm/utils';
import { getCatalog, clearSavedCatalog } from '../document/currentCatalogStorage';
import { handleAnonymousLogin } from '../login/handleAnonymousLogin';
import { LoginMatch, matchLoginUrl } from '../login/matchLoginUrl';
import { redirectToLogin } from '../login/redirectToLogin';
import { AppConfig, ConfigType, loadConfiguration } from './loadConfiguration';

export type AppCatalogConfig = {
    appConfig: AppConfig
    catalog: string
    loginMatch: LoginMatch | undefined
    configType: ConfigType
} | undefined

export async function handleConfiguration(history: History<unknown>, baseConfig: BaseConfig): Promise<AppCatalogConfig> {
    const loginMatch = matchLoginUrl(history)
    const catalog = getCatalog(loginMatch)
    const configType: ConfigType = loginMatch ? "LOGIN" : "APP"

    if (!loginMatch && !hasValidStoredLogin()) {
        // If user is not logged in, go to login page
        redirectToLogin(history, catalog)
        return
    }

    const appConfig = await loadConfiguration(baseConfig, configType, catalog, location.hostname, loginMatch?.traderId)

    if (!appConfig) {
        clearSavedCatalog()

        if (!location.pathname.startsWith("/login")) {
            // TODO: check if "redirectToLogin(catalog)" could be used here        
            history.replace("/login")
            return await handleConfiguration(history, baseConfig)
        }

        return
    }

    if (appConfig?.login?.props?.anonymousLogin) {
        if (await handleAnonymousLogin(catalog, appConfig)) {
            history.replace("/")

            return await handleConfiguration(history, baseConfig)
        }
    }

    return { appConfig, catalog, loginMatch, configType }
}