import { ajaxAuth } from "@tm/utils"
import { GetFilesRequest, GetFilesResponse } from "./models"

const baseUrl = "/data/TM.Next.Vouchers/vefiles"

export function getFiles(body: GetFilesRequest) {
    const url = `${baseUrl}/GetFiles`

    return ajaxAuth<GetFilesResponse>({ url, body }, undefined, undefined, true)
}
