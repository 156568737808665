import { SystemType } from "@tm/models"
import { IModules } from "../global"

/**
 * @memberof Portal
 * These are loaded into the NEXT DEV-TOOL (Chrome extension)
 * Also works without the extension -> to enable a rewrite just change the "false" to "true" for a rewrite
 * Example: ["Autodata", true],
*/
export const MODULES: IModules = {
    mdmModules: [
        ["ArticleNotes", false],
        ["Autodata", false],
        ["AWDataAWDoc", false],
        ["Bikes", false],
        ["Carmunication", false],
        ["CentralOrder", false],
        ["CommercialVehicles", false],
        ["CustomerInformation", false],
        ["DemoActivation", false],
        ["DMS", false],
        ["DutchOrderPlates", false],
        ["Drivemotive", false],
        ["FastCalculator", false],
        ["FastCalculatorNext", false],
        ["FastCheck", false],
        ["FastDTC", false],
        ["FastService", false],
        ["GpiDataTemot", false],
        ["GTIDataDAT", false],
        ["ImportNotes", false],
        ["IQT", false],
        ["MessagingModul", false],
        ["MyTruck", false],
        ["MyTruckPlus", false],
        ["OeInformation", false],
        ["OEReplacementChain", false],
        ["OfferCarousel", false],
        ["Offers", false],
        ["PartsIndicator", false],
        ["SAPCXMLIntegration", false],
        ["SAPOCIIntegration", false],
        ["ServiceDataHaynesPro", false],
        ["TechDataAllData", false],
        ["TechDataHaynesPro", false],
        ["TechDataHaynesProTruck", false],
        ["TechDataTecRMI", false],
        ["Telematics", false],
        ["TmDamageCalculationAsWegas", false],
        ["TmDamageCalculationDAT", false],
        ["TmDamageCalculationEurotax", false],
        ["TmDamageCalculationGlass", false],
        ["TmEurotax", false],
        ["TmTires", false],
        ["TmTrailer", false],
        ["TmTruck", false],
        ["TmWheels", false],
        ["ToolRent", false],
        ["TruckGarage", false],
        ["UnipartsPremium", false],
        ["VehicleRecords", false],
        ["VehicleRecords", false],
        ["WatchList", false],
        ["WheelsTyresConfigJfnMvc", false],
        ["WorkshopProfile", false],
        ["Eds", false],
        ["Sindri", false],
        ["Partslife", false],
        ["Vefiles", false]
    ],
    mdmModuleParameters: [
        ["FastCalculatorNext_SimpleWidget", false],
        ["FastCalculatorNext_widget_demo", false],
        ["HaynesPro_AWData", false],
        ["subuseradministration_isadmin", false],
        ["catalog_version__2", false],
        ["TechdatatecRMI_AWData", false],
        ["TechdatatecRMI_Tyres", false]
    ],
    mdmParameters: [
        ["articlelistpagesize", false],
        ["availabilityfirstwarehouse", false],
        ["catalogbrandimage", false],
        ["cataloglight", false],
        ["domarticlelist", false],
        ["fileimportbasket", false],
        ["hidemanufacturerlogo", false],
        ["remotemaintenanceurl", false],
        ["runtimeparameters", false],
        ["showdatasupplierlogos", false],
        ["urlagb", false],
        ["urlhomepage", false],
        ["urlimprint", false],
        ["urlprivacypolicy", false],
        ["vinquerymodulecosts", false],
        ["wspurldescription", false],
    ],
    externalSystems: {
        "aagonline": [
            ["10216", false],
            ["11281", false],
            ["11600", false],
            ["12850", false],
        ],
        "atevis": [
            ["12648", false]
        ],
        "autonet-ro": [
            ["7712", false],
            ["7714", false],
            ["7715", false],
            ["9125", false],
            ["9126", false],
            ["9128", false],
            ["9514", false],
            ["10556", false]
        ],
        "bm": [
            ["8369", false],
            ["9378", false],
            ["9418", false],
            ["13664", false]
        ],
        "bm-telesales": [
            ["9419", false],
            ["10241", false]
        ],
        "bmc": [
            ["11545", false],
            ["11546", false],
            ["11547", false],
        ],
        "car": [
            ["10559", false],
        ],

        "catalogo": [
            ["13080", false],
        ],
        "cgacat": [
            ["10667", false],
        ],
        "ciak": [
            ["7805", false],
            ["7806", false],
        ],
        "contitrade": [
            ["11313", false],
        ],
        "coparts": [
            ["7694", false],
            ["7695", false],
            ["7696", false],
            ["7697", false],
            ["7698", false],
            ["7903", false],
            ["7948", false],
            ["7960", false],
            ["8737", false],
            ["11902", false],
            ["12042", false],
            ["12094", false],
            ["12136", false],
            ["12147", false],
            ["12993", false],
            ["13172", false],
        ],
        "coparts-telesales": [
            ["11282", false],
            ["12722", false],
            ["13240", false],
            ["13415", false],
            ["13808", false],
            ["13944", false],
        ],
        "create-business": [
            ["8480", false],
            ["8481", false],
            ["8482", false],
            ["8483", false],
            ["8484", false],
            ["8489", false],
            ["8555", false],
            ["8556", false],
            ["8557", false],
            ["8835", false],
            ["8836", false],
            ["8837", false],
            ["8838", false],
            ["8839", false],
            ["8840", false],
            ["8841", false],
            ["8842", false],
            ["8843", false],
            ["8881", false],
            ["8882", false],
            ["9697", false],
            ["9698", false],
            ["11193", false],
            ["15155", false]
        ],
        "elekat": [
            ["11147", false],
            ["12933", false],
        ],
        "esa": [
            ["7765", false],
            ["7766", false],
        ],
        "esa-test": [
            ["10182", false],
            ["10183", false],
        ],
        "gmt": [
            ["8814", false],
        ],
        "heil": [
            ["7028", false],
            ["7031", false],
            ["7161", false],
            ["8743", false],
            ["8744", false],
            ["8745", false],
            ["8746", false],
            ["8747", false],
            ["8748", false],
            ["8749", false],
            ["8750", false],
            ["8751", false],
            ["8752", false],
            ["11045", false],
            ["13142", false]
        ],
        "hess": [
            ["7124", false],
            ["7125", false],
            ["8354", false],
            ["8709", false],
            ["10102", false],
        ],
        "hlgroup": [
            ["10988", false],
        ],
        "hostettler": [
            ["7131", false],
            ["7778", false],
            ["7779", false],
            ["7780", false],
            ["7781", false],
            ["8434", false],
            ["8436", false],
            ["8473", false],
        ],
        "hostettler-telesales": [
            ["8757", false],
            ["8761", false],
            ["8763", false],
        ],
        "iaponiki": [
            ["8028", false],
            ["8826", false],
            ["8827", false],
            ["8828", false],
            ["13810", false],
        ],
        "iaponiki-telesales": [
            ["14262", false],
        ],
        "infopro": [
            ["12591", false],
        ],
        "infoproaag": [
            ["13236", false],
        ],
        "kgk-estonia": [
            ["12252", false],
        ],
        "lkqeurope": [
            ["10255", false],
        ],
        "motocat": [
            ["14026", false],
        ],
        "neimcke": [
            ["6043", false],
            ["6045", false],
            ["6046", false],
            ["6047", false],
            ["6049", false],
            ["6051", false],
            ["7474", false],
            ["7979", false],
            ["10880", false],
            ["12977", false],
            ["14401", false],
            ["14406", false],
        ],
        "neimcke_int": [
            ["6806", false],
            ["6807", false],
            ["6808", false],
            ["6809", false],
            ["6812", false],
            ["6814", false],
            ["7475", false],
        ],
        "neimcke-telesales": [
            ["8493", false],
            ["8494", false],
            ["8495", false],
            ["8496", false],
            ["8497", false],
            ["8499", false],
            ["8501", false],
            ["8507", false],
            ["8511", false],
            ["10833", false],
            ["13021", false],
            ["14403", false],
            ["14404", false],
        ],
        "neimcke-test": [
            ["6454", false],
            ["6455", false],
            ["6456", false],
            ["6457", false],
            ["6460", false],
            ["6462", false],
            ["7980", false],
            ["8816", false],
            ["8817", false],
            ["10881", false],
            ["12976", false],
            ["14402", false],
            ["14407", false],
        ],
        "neimckeat": [
            ["7961", false],
            ["7962", false],
            ["7963", false],
            ["7964", false],
            ["7965", false],
            ["7967", false],
            ["7969", false],
            ["7975", false],
            ["7981", false],
            ["10882", false],
            ["14405", false],
            ["14408", false],
        ],
        "neimckeat-test": [
            ["15117", false],
            ["15119", false],
            ["15120", false],
            ["15123", false],
            ["15125", false],
            ["15131", false],
            ["15135", false],
            ["15136", false],
            ["15138", false],
            ["15139", false],
            ["15140", false],
        ],
        "default": [
            ["10825", false],
        ],
        "promofficina": [
            ["11389", false],
        ],
        "promofficina-tsm": [
            ["12300", false],
        ],
        "pv": [
            ["5814", false],
            ["5815", false],
            ["5820", false],
            ["6938", false],
            ["6969", false],
            ["6987", false],
            ["6997", false],
            ["6998", false],
            ["6999", false],
            ["7000", false],
            ["7001", false],
            ["7002", false],
            ["7003", false],
            ["7004", false],
            ["7005", false],
            ["7007", false],
            ["7008", false],
            ["7009", false],
            ["7010", false],
            ["7675", false],
            ["7982", false],
            ["8936", false],
            ["11808", false],
            ["12978", false],
        ],
        "pv_int": [
            ["6465", false],
            ["6466", false],
            ["6471", false],
            ["6939", false],
            ["6975", false],
            ["6989", false],
            ["7012", false],
            ["7013", false],
            ["7014", false],
            ["7015", false],
            ["7016", false],
            ["7017", false],
            ["7018", false],
            ["7019", false],
            ["7020", false],
            ["7021", false],
            ["7022", false],
            ["7023", false],
            ["7024", false],
            ["7676", false],
            ["7983", false],
            ["8938", false],
            ["11809", false],
            ["12979", false],
        ],
        "pv-telesales": [
            ["8527", false],
            ["8529", false],
            ["8530", false],
            ["8546", false],
            ["8547", false],
            ["9095", false],
            ["9097", false],
            ["11810", false],
            ["13022", false],
        ],
        "rhiagch": [
            ["13518", false],
        ],
        "rhiagch-test": [
            ["14011", false],
        ],
        "rhiagch-tsm": [
            ["15602", false],
        ],
        "rete1one": [
            ["13165", false],
        ],
        "romnes": [
            ["10814", false],
        ],
        "stakistsm": [
            ["8821", false],
        ],
        "stg": [
            ["6900", false],
            ["6963", false],
            ["6990", false],
            ["7984", false],
            ["8818", false],
            ["8934", false],
            ["11805", false],
            ["12980", false],
        ],
        "stg_int": [
            ["6970", false],
            ["6982", false],
            ["6991", false],
            ["8819", false],
            ["8935", false],
        ],
        "stg-aut": [
            ["11805", false],
            ["12223", false],
            ["12226", false],
            ["12227", false],
            ["12235", false],
            ["12236", false],
            ["12237", false],
            ["12238", false],
            ["12239", false],
            ["13375", false]
        ],
        "stg-telesales": [
            ["8015", false],
            ["8018", false],
            ["8019", false],
            ["9094", false],
            ["9096", false],
            ["11807", false],
            ["13023", false],
        ],
        "stg-test": [
            ["6901", false],
            ["7458", false],
            ["7459", false],
            ["7985", false],
            ["8819", false],
            ["8935", false],
            ["11806", false],
            ["12981", false],
        ],
        "stgaut-test": [
            ["13877", false],
            ["13880", false],
            ["13881", false],
            ["13889", false],
            ["13890", false],
            ["13891", false],
            ["13892", false],
            ["13893", false],
            ["13894", false],
        ],
        "teccat": [
            ["12437", false],
            ["12677", false]
        ],
        "texkat": [
            ["11049", false],
        ],
        "tokic": [
            ["8212", false],
        ],
        "tokic-tsm": [
            ["12637", false],
        ],
        "topautoteile": [
            ["11331", false],
        ],
        "wm-telesales": [
            ["654", false],
        ],
        "wmkat-dk": [
            ["10681", false]
        ],
        "wmkat-it": [
            ["12568", false]
        ],
    },
    otherModules: [
        ["3-34", false],
        ["3-67", false],
        ["3-70", false],
        ["3-87", false],
        ["3-9994", false],
        ["feedback", false],
    ],
    systemModules: {
        [SystemType.Smartclient]: [
            ["TmEurotax", false],
            ["TmTires", false],
            ["TmWheels", false],
            ["AwDataAwdoc", false],
            ["Autodata", false],
            ["TechDataTecRMI", false],
            ["HaynesPro_Awdata", false]
        ],
        [SystemType.Redesign]: [
            ["TmEurotax", false],
            ["TmTires", false],
            ["TmWheels", false],
            ["TmDamageCalculationDAT", false],
            ["TmDamageCalculationGlass", false],
            ["AwDataAwdoc", false],
            ["Autodata", false],
            ["TechDataTecRMI", false],
            ["HaynesPro_Awdata", false],
            ["Eds", false],
        ]
    },
    lateModules: [
        ["telesales", false],
        ["devspire", false],
        ["memotool", false]
    ],
    traderConfigs: [
        ["199", false],
        ["226", false],
        ["313", false],
        ["323", false],
        ["416", false],
        ["497", false],
        ["520", false],
        ["578", false],
        ["1138", false],
    ]
}
